import React from 'react'
import { List } from './styles'
import { Link } from 'react-router-dom'

function Selects() {
  return (
    <List>
      <Link to='/'>INÍCIO</Link>
      <Link to='/a-pollipark'>A POLLIPARK</Link>
      <Link to='/solucao'>SOLUÇÃO</Link>
      <Link to='https://wa.me/5548988754434' target='_blank'>
        SUPORTE
      </Link>
      <Link to='/galeria'>GALERIA</Link>
    </List>
  )
}

export default Selects
